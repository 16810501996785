<template>
	<div id="generalContent" class="py-2 px-5">
		<div class="row mt-2 align-items-center">
			<div class="col-md-4">
				<div class="row my-4 mb-md-4">
					<div class="col-md-12">
						<div class="sidebar_toggler">
							<img
								src="../../assets/icons/menu.svg"
								class="img-fluid sidebar_icon float-md-left float-right"
								v-b-toggle.main_sidebar
								alt="menu"
							/>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-md-12">
						<div class="content_title">
							<p class="block_title text-white text-capitalize">
								{{ title }}
								<img
									v-if="title === 'video series'"
									src="../../assets/icons/series.svg"
									alt="video_series"
									class="ml-2 video_series_icon"
								/>
								<img
									v-else-if="title === 'audio series'"
									src="../../assets/icons/audio.svg"
									alt="audio_series"
									class="ml-2 audio_series_icon"
								/>
								<img
									v-else-if="title === 'videos'"
									src="../../assets/icons/videos.svg"
									alt="videos"
									class="ml-2 videos_icon"
								/>
								<img
									v-else-if="title === 'articles'"
									src="../../assets/icons/articles.svg"
									alt="articles"
									class="ml-2 articles_icon"
								/>
								<img
									v-else-if="title === 'books'"
									src="../../assets/icons/books.svg"
									alt="books"
									class="ml-2 books_icon"
								/>
								<img
									v-else-if="title === 'apps'"
									src="../../assets/icons/app-blue.svg"
									alt="books"
									class="ml-2 books_icon"
								/>
							</p>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-4 text-center">
				<router-link :to="{ name: 'home' }">
					<img class="general_content_logo" src="../../assets/logos/Al-haq-Logo-selected.svg" alt="" />
				</router-link>
			</div>
			<div class="col-md-4">
				<b-input-group class="search_group mt-md-5 mt-4 pt-md-4 pt-0" size="md">
					<b-form-input v-model="search_text" class="category_search py-2" :placeholder="'Search ' + title">
					</b-form-input>
					<b-input-group-append>
						<b-button class="category_search_button">
							<b-icon-search></b-icon-search>
						</b-button>
					</b-input-group-append>
				</b-input-group>
			</div>
		</div>
		<div class="row mt-4">
			<div class="col-md-3" v-for="(d, index) in computedData" :key="index">
				<b-card class="block_cards mt-4 bg-transparent" body-class="p-1" @click="GoToPage(d)">
					<div class="latest-card-img-wrapper">
						<b-card-img :src="baseURL + d.thumbnail"></b-card-img>
					</div>
					<b-button class="bg-transparent border-0 p-0 m-0 text-left" v-b-tooltip.hover.v-info :title="d.title">
						<b-card-title class="mt-2 card_title textMaxLine"> {{ d.title }}</b-card-title>
					</b-button>
					<b-card-sub-title v-if="d.speaker_details" class="text-truncate mt-1 card_details">{{
						d.speaker_details.name
					}}</b-card-sub-title>
					<b-card-sub-title v-if="d.speaker" class="text-truncate mt-1 card_details">{{ d.speaker }}</b-card-sub-title>
					<b-card-sub-title v-if="d.author_details" class="text-truncate mt-1 card_details">{{
						d.author_details.name
					}}</b-card-sub-title>
					<b-button v-if="d.subcategory_details" class="card_category px-1 py-0 mt-3 w-25">{{
						d.subcategory_details ? d.subcategory_details.name : ''
					}}</b-button>
				</b-card>
			</div>
		</div>
		<div class="row mt-5">
			<div class="col-md-12">
				<b-pagination
					align="center"
					v-model="pagination.current_view"
					:total-rows="data.length - 1"
					:per-page="pagination.per_view"
					first-text="First"
					next-text="Next"
					prev-text="Prev"
					last-text="Last"
				></b-pagination>
			</div>
		</div>

		<b-modal
			body-class="p-0 shadow"
			content-class="gen-video-content"
			header-close-variant="light"
			header-class="gen-video-header"
			footer-class="gen-video-footer"
			ref="gen-player-modal2"
			no-close-on-backdrop
			@close="gen_src = null"
			title="Playing Video"
			size="lg"
		>
			<iframe id="gen_player" :src="gen_src" frameborder="0" allowfullscreen></iframe>
		</b-modal>
	</div>
</template>

<script>
import general_sidebar from './general_sidebar'
import config from '../../assets/config'
export default {
	props: {
		data: {
			default: [],
			type: Array,
		},
		title: {
			default: 'content',
			type: String,
		},
	},
	data() {
		return {
			search_text: '',
			baseURL: config.baseURL,
			yt_url: 'https://www.youtube.com/embed/',
			gen_src: '',
			pagination: {
				per_view: 16,
				current_view: 1,
			},
		}
	},
	mounted() {
		const { search } = this.$route.query
		this.search_text = search || ''
	},
	methods: {
		GoToPage(d) {
			if (this.title === 'video series') {
				this.$router.push({
					name: 'watch-playlist',
					params: {
						pl_id: d.id,
						subcat_id: d.subcategory_details ? d.subcategory_details.id : '',
						tab_index: 0,
					},
				})
			} else if (this.title === 'audio series') {
				this.$router.push({
					name: 'listen-audio',
					params: {
						audioc_id: d.id,
						subcat_id: d.subcategory_details ? d.subcategory_details.id : '',
						tab_index: 1,
					},
				})
			} else if (this.title === 'articles') {
				this.$router.push({
					name: 'read-article',
					params: {
						art_id: d.id,
						subcat_id: d.subcategory_details ? d.subcategory_details.id : '',
						tab_index: 2,
					},
				})
			} else if (this.title === 'books') {
				this.$router.push({
					name: 'sub-category',
					params: {
						id: d.subcategory_details ? d.subcategory_details.id : '',
						tab_index: 3,
					},
				})
			} else if (this.title === 'videos') {
				this.gen_src = this.yt_url + d.url + '?rel=0&autoplay=1'
				this.$refs['gen-player-modal2'].show()
			}
		},
	},
	computed: {
		computedData() {
			return this.data.filter(
				(item, index) =>
					item.title.toLowerCase().includes(this.search_text.toLowerCase()) &&
					index >= this.pagination.per_view * (this.pagination.current_view - 1) &&
					index <= this.pagination.per_view * this.pagination.current_view
			)
		},
	},
	components: {
		'g-sidebar': general_sidebar,
	},
}
</script>

<style scoped>
.textMaxLine {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	height: 30px;
	overflow: hidden;
	word-break: break-word;
}
.block_title {
	font-family: brandFont;
	font-size: 32px;
	font-weight: 400;
	width: fit-content;
}
.block_title::before {
	font-size: 22px;
	content: '';
	margin-right: 15px;
	border-left: 8px solid #28abe1;
}
.block_title::after {
	content: ' ';
	margin-top: 5px;
	display: block;
	border: 0.5px solid white;
}
.general_content_logo {
	width: 180px !important;
	height: 180px !important;
}
.card {
	border: none;
}
.card .card-title {
	color: #28abe1;
	font-weight: bolder;
}
.card .card-subtitle {
	color: white !important;
}
.card_category {
	min-width: fit-content;
	color: #8a8d91 !important;
	background-color: transparent !important;
	box-shadow: none !important;
	font-size: 12px !important;
}
.card:hover {
	overflow: hidden;
	cursor: pointer;
}
.card_title,
.card_details {
	font-size: 14px;
}
.card_description {
	font-size: 14px;
}
.sidebar_icon {
	width: 40px !important;
	height: 40px !important;
	cursor: pointer !important;
}
.sidebar_icon:focus {
	outline: none !important;
}
.category_search {
	background-color: transparent !important;
	color: white !important;
	box-shadow: none !important;
	border-radius: 0 !important;
	font-family: brandFont-subcat;
}
.category_search:focus {
	border: 1px solid #28abe1 !important;
}
.category_search_button {
	background-color: #28abe1 !important;
	border: 1px solid #28abe1 !important;
	box-shadow: none !important;
	color: black !important;
	border-radius: 0 !important;
}
.text-capitalize {
	font-variant-caps: all-petite-caps;
}
.latest-card-img-wrapper {
	overflow: hidden;
}
.video_series_icon,
.audio_series_icon,
.articles_icon,
.books_icon,
.videos_icon {
	width: 40px !important;
	height: 40px !important;
}
.pagination >>> .page-link {
	box-shadow: 5px 5px 8px 1px black;
	border: none !important;
	margin: 10px;
	padding: 12px;
	width: 50px;
	font-family: brandFont-subcat;
	font-size: 14px;
	cursor: pointer;
	background-color: #080717 !important;
	color: #ffffff !important;
}
.pagination >>> .page-link:hover,
.pagination >>> .page-link:focus {
	background-color: #28abe1 !important;
}
#gen_player {
	width: 100%;
	max-height: 380px;
	height: 380px;
	border-bottom: none !important;
}
</style>
