<template>
    <div id="search-view">
        <g-contents :title="$route.params.title" :data="$route.params.data"></g-contents>
    </div>
</template>

<script>
    import generalContent from '../components/public/general_content'
    import { mapActions, mapGetters } from 'vuex'

    export default {
        components: {
            'g-contents': generalContent
        }
    }
</script>

<style>
</style>